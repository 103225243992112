import { useAnalyticsStore } from '@/stores/analytics-store';
import { useDashboardStore } from '@/stores/dashboard-store';
import { useHarbourStore } from '@/stores/harbour-store';
import Vue, { computed, ref } from 'vue';

export function useLinkObject(link = {}) {
  const harbourStore = useHarbourStore();
  const dashboardStore = useDashboardStore();
  const analyticsStore = useAnalyticsStore();

  // Helper function to parse arrays of strings into JSON
  const restoreJSON = (arr) => {
    if (!arr) return [];
    try {
      return arr.map((item) => JSON.parse(item));
    } catch (e) {
      return [];
    }
  };

  const id = ref(link?.id);
  const superdocId = ref(link?.superdoc_id);

  // This title is for the creater/org, shows up in the dashboard
  // Comes from client_importbyurl_linkrecordtitle
  const title = ref(link?.title);

  // This title is inside the link itself and is user-facing
  const linktitle = ref(link?.linktitle);

  const lastUpdate = ref(link?.last_update);
  const authMethod = ref(link?.auth_method);

  // Agreement
  const agreementId = ref(link.agreement_id);
  // duplicate "title" var (left to preserve function contract)
  const templateTitle = ref(link?.title);
  // duplicate "agreementId" var (left to preserve function contract)
  const templateId = ref(link.agreement_id);
  const isTemplate = ref(link?.is_template);
  const templateGroupId = ref(link?.template_group_id);

  const awaitingMyReviewSigned = ref(false);

  const organizationName = ref(link?.organization_name || null);
  // CK
  const ckeditorAgreementId = ref(link?.ckeditor_agreement_id || null);

  // Signers & Completion state
  const emailRecipients = ref(restoreJSON(link?.email_recipients));
  const emailRecipientsCompleted = ref(restoreJSON(link?.email_recipients_completed));
  const numCompleted = ref(emailRecipients.value?.length);
  const totalSignersRequired = ref(emailRecipientsCompleted.value?.length);

  const isSent = ref(link?.is_sent);
  const isApiCreated = ref(link?.is_api_created);

  const customInputs = ref(link?.custom_inputs);
  const totalViews = ref(link?.total_views || 0);
  const viewedBy = ref(link?.viewed_by || []);

  const url = ref(link?.url);
  const active = ref(link?.active);
  const gcsPath = ref(link?.gcs_path);
  const folder = ref(link?.folder);
  const folderLinkName = ref(link?.folder_name);
  const sharedGroups = ref(link?.group_ids || []);

  // Approval workflows
  const workflowsStatus = ref(link?.workflows_status || []);
  const hasPendingApproval = computed(() => {
    return workflowsStatus.value.some((workflow) => {
      const isComplete = workflow.state === 'completed';
      if (isComplete) return false;
      return workflow.pending_approval_ids.includes(id.value);
    });
  });

  const hasApproval = ref(link?.has_approval || false);

  const folderName = computed(() => {
    const id = folder.value?.split('/').pop();
    const isHome = folder.value?.startsWith('folder-home');
    const name = harbourStore.myFolders.find((folder) => folder.id === id)?.name;
    let linkName = folderLinkName.value;
    if (isHome) linkName = 'My folders';
    const resultName = name || linkName || 'My folders';
    return resultName;
  });

  // Paragon
  const automations = ref(link?.automations);

  // Creator info
  const creatorName = ref(link?.creator_name);

  const creatorEmail = ref(link?.creator_email);
  const created = ref(link?.created);

  // Sent messages
  const messages = ref([]);

  // Live signer sessions
  const sessionsList = ref([]);
  const activeSessions = ref([]);
  const totalInputs = ref(0);

  // Previously used 'row' data for legacy use
  const row = ref(link);

  function getActiveSigners() {
    return emailRecipients.value?.filter((signer) => !signer.reassigned);
  }
  const setValue = (key, value) => {
    const rowNode = dashboardStore.gridApi?.getRowNode(id.value);
    rowNode?.setDataValue(key, value);
    active.value = value;
  };

  // Link submissions
  const submissions = ref(link?.submissions || []);

  // Input search terms
  const searchMatch = ref('');

  const initSigners = () => {
    const emailRecipients = link?.email_recipients || exposedData['emailRecipients'] || [];
    const signedItems =
      link?.email_recipients_completed || exposedData['emailRecipientsCompleted'] || [];

    !exposedData['emailRecipients'] &&
      (emailRecipients.value = emailRecipients.map((recipient) => JSON.parse(recipient)));
    !exposedData['emailRecipientsCompleted'] &&
      (emailRecipientsCompleted.value = signedItems.map((recipient) => JSON.parse(recipient)));
  };

  const setCompletionState = () => {
    numCompleted.value = emailRecipientsCompleted.value.length;
    totalSignersRequired.value = getActiveSigners().length;
  };

  // Completion State
  const getCompletionState = computed(() => {
    if (authMethod.value?.authmode === 'EMAILS') return updateEmailsCompletion();
    else if (totalSignersRequired.value > 0) return 'COMPLETED';
    else return updatePublicCompletion();
  });
  const updatePublicCompletion = () =>
    !!submissions.value && submissions.value.length > 0 ? 'COMPLETED' : 'PENDING';
  const updateEmailsCompletion = () => {
    if (numCompleted.value === 0) return 'PENDING';
    return numCompleted.value === totalSignersRequired.value ? 'COMPLETED' : 'PENDING';
  };

  const linkBrandImage = ref(null);
  const brandDomain = ref(null);
  const getBrandDomain = computed(() => {
    return brandDomain;
  });

  // Get the brand image from the first domain found that does not match the creator's domain
  // If none found, try the first signer's domain
  // Failing that, save null and display fontawesome icon
  const getBrandImage = async () => {
    const creatorDomain = dashboardStore.getDomainFromEmail(creatorEmail.value);
    const nonCreatorEmail = emailRecipients.value.filter((signer) => {
      const domain = dashboardStore.getDomainFromEmail(signer.recipientemail);
      const reassigned = signer.reassigned;
      return domain !== creatorDomain && !reassigned;
    });

    let signerForBrand;
    if (nonCreatorEmail.length) signerForBrand = nonCreatorEmail[0];
    else signerForBrand = emailRecipients.value[0];

    if (!signerForBrand) return;
    const signerEmail = signerForBrand.recipientemail;
    const domain = dashboardStore.getDomainFromEmail(signerEmail);
    brandDomain.value = domain;

    const hasDomainLoaded = dashboardStore.signerBrands.some((brand) => brand.domain === domain);

    if (hasDomainLoaded) {
      linkBrandImage.value = dashboardStore.signerBrands.find((brand) => brand.domain === domain);
      return;
    }

    dashboardStore.signerBrands.push({ domain, image: null });

    const result = await Vue.prototype.$harbourData.get(`/core/brand/${domain}`);
    const item = dashboardStore.signerBrands.find((brand) => brand.domain === domain);
    item.image = result.data;
  };

  // Get profile picture for all signers
  const loadProfilePictures = async () => {
    const emailsFromRecipients = emailRecipients.value.map((recipient) => recipient.recipientemail);
    harbourStore.loadProfilePictures(emailsFromRecipients)
  };

  // Update this object
  const update = () => {
    initSigners();
    loadProfilePictures();
    authMethod.value?.authmode === 'EMAILS' && getBrandImage();
    setCompletionState();
  };

  const flashCell = (key = null) => {
    const rowNode = dashboardStore.gridApi?.getRowNode(id.value);
    if (!rowNode) return;

    if (key) {
      const columns = [key];
      if (key === 'emailRecipientsCompleted') columns.push('emailRecipients', 'completionState');
      dashboardStore.gridApi.flashCells({ rowNodes: [rowNode], columns });
    } else {
      dashboardStore.gridApi.flashCells({ rowNodes: [rowNode] });
    }
    dashboardStore.gridApi.refreshClientSideRowModel('filter');
  };

  // Add submission to the list of submissions
  const addSubmission = (data) => {
    submissions.value.unshift(data);
    setCompletionState();
    flashCell('completionState');
  };

  // map server side column names to camelCase
  const convertColumnName = (column) => {
    return column.replace(/(_\w)/g, function (match) {
      return match[1].toUpperCase();
    });
  };

  // Is signer completed
  const isSignerCompleted = (signerEmail) => {
    return emailRecipientsCompleted.value?.some((item) => item.recipientemail === signerEmail);
  };

  // Get a list of pending signer emails
  const emailsToSign = () => {
    if (!emailRecipients.value) return [];
    const pendingSignerEmails = [];
    const hasSignerOrder =
      authMethod.value?.authmode === 'EMAILS' && authMethod.value?.isenforcingsignreviewrorder;

    if (hasSignerOrder) {
      const signer = emailRecipients.value?.find(
        (signer) => !isSignerCompleted(signer.recipientemail),
      );
      if (signer) pendingSignerEmails.push(signer.recipientemail);
      return pendingSignerEmails;
    }

    emailRecipients.value?.forEach((signer) => {
      if (!isSignerCompleted(signer.recipientemail)) {
        pendingSignerEmails.push(signer.recipientemail);
      }
    });
    return pendingSignerEmails;
  };

  const sessionData = ref([]);
  const columnsToParse = ['custom_input_values', 'email_recipients', 'email_recipients_completed'];
  const exposedData = {
    id,
    superdocId,
    agreementId,
    ckeditorAgreementId,
    templateGroupId,
    title,
    linktitle,
    isTemplate,
    templateTitle,
    templateId,
    lastUpdate,
    authMethod,
    numCompleted,
    totalSignersRequired,
    customInputs,
    emailRecipients,
    emailRecipientsCompleted,
    totalViews,
    url,
    active,
    gcsPath,
    folder,
    sharedGroups,
    folderName,
    submissions,
    automations,
    messages,
    viewedBy,
    linkBrandImage,
    searchMatch,
    sessionsList,
    sessionData,
    totalInputs,
    awaitingMyReviewSigned,
    organizationName,
    isSent,
    isApiCreated,

    // Admin only
    creatorName,
    creatorEmail,
    created,

    // Workflows
    workflowsStatus,
    hasPendingApproval,
    hasApproval, // This indicates if this link was ever part of an approval, not necessarily pending or active

    // Row
    row,
  };

  // Update the object key based on the db column name
  const updateByColumnName = (column, value) => {
    // Update a value by column name
    let key = convertColumnName(column);
    if (!(key in exposedData)) return;
    const node = getNode(id.value);
    let newValue = value;

    if (columnsToParse.includes(column)) {
      if (Array.isArray(value)) {
        newValue = value.map((item) => JSON.parse(item));
      } else {
        newValue = JSON.parse(value);
      }
    }

    try {
      node.setDataValue(key, newValue);
    } catch (e) {
      // No need to do anything here. Sometimes we update data that is not a grid column.
    }
    exposedData[key] = newValue;
    setCompletionState();

    const flashWholeRow = ['submissions', 'email_recipients_completed'];
    // Flashing cell
    if (flashWholeRow.includes(key)) key = null;
    flashCell(key);

    // Refresh analytics if a new submission was added
    if (column === 'submissions') {
      analyticsStore.refreshAnalytics();
    }
  };

  /* Actions */

  const getNode = () => dashboardStore.gridApi?.getRowNode(id.value);

  exposedData.updateByColumnName = updateByColumnName;
  exposedData.update = update;
  exposedData.addSubmission = addSubmission;
  exposedData.getCompletionState = getCompletionState;
  exposedData.flashCell = flashCell;
  exposedData.getNode = getNode;
  exposedData.setValue = setValue;
  exposedData.getBrandImage = getBrandImage;
  exposedData.emailsToSign = emailsToSign;
  exposedData.getBrandDomain = getBrandDomain;
  exposedData.loadProfilePictures = loadProfilePictures;

  return exposedData;
}
