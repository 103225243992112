<script setup>
import Vue, { reactive, ref, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { AgGridVue } from 'ag-grid-vue';
import { useSettingsPageStore } from '@/pages/Settings/stores/settings-store.js';

const settingsPageStore = useSettingsPageStore();
const {
  usersData,
  isOrgAdmin,
  getUserEmail,
} = storeToRefs(settingsPageStore);

const emit = defineEmits(['add-users']);
const currentGroupId = ref(null);
const currentGroupData = ref(null);
const actionsDisabled = ref(true);
const gridHeight = ref('');

const defaultColDef = {
  filter: true,
  sortable: true,
  resizable: true,
  lockPinned: true,
  editable: false,
  menuTabs: ['filterMenuTab'],
};
const gridOptions = {
  rowBuffer: 4,
  suppressMenuHide: true,
  suppressContextMenu: true,
  suppressMovableColumns: true,
  allowDragFromColumnsToolPanel: false,
  suppressCellFocus: true,
  rowHeight: 60,
  overlayNoRowsTemplate: `<span class="ag-overlay-loading-center">- No added users yet -</span>`,
};
const columnDefs = [
  {
    field: 'email',
    headerValueGetter: () => `Members (${currentGroupData.value?.members.length})`,
    minWidth: 260,
    flex: 1,
    hide: false,
    filter: 'agTextColumnFilter',
    headerClass: 'align-left',
    cellRenderer: 'UserNameRenderer',
    cellRendererParams: {
      getUserDetails: (email) => usersData.value[email],
    },
    comparator: (valueA, valueB) => {
      const emailALower = valueA.toLowerCase();
      const emailBLower = valueB.toLowerCase();
      if (emailALower === emailBLower) return 0;
      return (emailALower > emailBLower) ? 1 : -1;
    },
  },
  {
    field: 'role',
    headerName: 'Role',
    cellRenderer: 'UserListRolesRenderer',
    width: 120,
    resizable: false,
    suppressMenu: true,
    hide: false,
    sortable: false,
    cellRendererParams: {
      isActionsDisabled: () => actionsDisabled.value,
      getGroupId: () => currentGroupId.value,
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellRenderer: 'UserListActionsRenderer',
    width: 100,
    resizable: false,
    suppressMenu: true,
    hide: false,
    sortable: false,
    cellRendererParams: {
      isActionsDisabled: () => actionsDisabled.value,
      getGroupId: () => currentGroupId.value,
    },
  },
];


const onGridReady = (evt, params) => {
  settingsPageStore.detailsGridApi = evt.api;
  settingsPageStore.detailsGridColumnApi = evt.columnApi;

  currentGroupId.value = params.data.group_id;
  currentGroupData.value = params.data;

  const isGroupAdmin = isUserGroupAdmin();
  actionsDisabled.value = !isGroupAdmin;

  calcDetailGridHeight(params);
};

const releaseGridResources = () => {
  settingsPageStore.detailsGridApi = null;
  settingsPageStore.detailsGridColumnApi = null;
};

const isUserGroupAdmin = () => {
  if (isOrgAdmin.value) return true;
  const currentUserGroupRole = currentGroupData.value?.members.find(member => member.email === getUserEmail.value)?.role;
  return currentUserGroupRole === 'admin';
};

const calcDetailGridHeight = (params) => {
  const offset = 80;
  const maxHeight = 545;
  const allDetailRowHeight = params.data.members.length *
    params.api.getSizesForCurrentTheme().rowHeight;
  gridHeight.value = `${allDetailRowHeight + offset > maxHeight ? maxHeight : allDetailRowHeight + offset}px`;
}

const getColumnDefs = (data) => {
  return [
    {
      field: 'email',
      headerValueGetter: () => {
        return `Members (${data?.members.length})`
      },
      minWidth: 260,
      flex: 1,
      hide: false,
      filter: 'agTextColumnFilter',
      headerClass: 'align-left',
      cellRenderer: 'UserNameRenderer',
      cellRendererParams: {
        getUserDetails: (email) => usersData.value[email],
      },
      comparator: (valueA, valueB) => {
        const emailALower = valueA.toLowerCase();
        const emailBLower = valueB.toLowerCase();
        if (emailALower === emailBLower) return 0;
        return (emailALower > emailBLower) ? 1 : -1;
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      cellRenderer: 'UserListRolesRenderer',
      width: 120,
      resizable: false,
      suppressMenu: true,
      hide: false,
      sortable: false,
      cellRendererParams: {
        isActionsDisabled: () => actionsDisabled.value,
        getGroupId: () => currentGroupId.value,
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      cellRenderer: 'UserListActionsRenderer',
      width: 100,
      resizable: false,
      suppressMenu: true,
      hide: false,
      sortable: false,
      cellRendererParams: {
        isActionsDisabled: () => actionsDisabled.value,
        getGroupId: () => currentGroupId.value,
        isDefaultGroup: () => currentGroupData.value.is_default,
      },
    },
  ];
};

onUnmounted(() => {
  releaseGridResources();
});
</script>

<template>
  <div class="detail-grid-container">
    <AgGridVue
      class="ag-theme-alpine detail-grid"
      :style="{height: gridHeight}"
      :rowData="params.data.members"
      :gridOptions="gridOptions"
      :columnDefs="getColumnDefs(params.data)"
      :headerHeight="42"
      :defaultColDef="defaultColDef"
      :animateRows="false"
      @grid-ready="evt => onGridReady(evt, params)"
    />
  </div>
</template>

<style scoped lang="postcss">
.detail-grid-container {
  width: 100%;
  padding: 0 40px;
  font-size: 12px;
  background-color: #F5F5F5;
  >>> .ag-root-wrapper {
    background-color: #F5F5F5;
  }

  .detail-grid {
    --ag-cell-horizontal-padding: 0;
    --ag-icon-font-family: 'agGridAlpine';
    --ag-header-height: 200;
    --ag-header-foreground-color: #666666;
    --ag-header-cell-hover-background-color: none;
    --ag-header-cell-moving-background-color: none;
    --ag-row-hover-color: none;
    --ag-header-column-resize-handle-display: inline-block;
    --ag-header-column-resize-handle-height: 25%;
    --ag-header-column-resize-handle-width: 2px;
    --ag-header-column-resize-handle-color: #0c0c0c2b;
    --ag-tooltip-background-color: #ffffff99;
    --ag-borders: none;
    font-size: 12px !important;
  }

  .detail-grid >>> .ag-header-cell {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    border-bottom: 1px solid transparent;
    padding: 12px;
  }

  .detail-grid >>> .ag-header {
    background-color: transparent;
    font-weight: 700;
    border-bottom: none;
  }

  .detail-grid >>> .ag-header-active {
    background-color: transparent;
  }

  .detail-grid >>> .ag-row {
    border-bottom: 1px solid #DBDBDB;
    z-index: 1;

    &.spanned-row {
      z-index: 2;
    }
  }

  .detail-grid >>> .ag-header-cell-text {
    width: 100%;
  }

  .detail-grid >>> .ag-column-select-header {
    display: none;
  }

  .detail-grid >>> .cell-span {
    min-height: 120px;
    background-color: #fff;
  }

  .detail-grid >>> .ag-overlay-loading-center {
    padding: 20px;
    background-color: #ffffff99;
    border-radius: 5px;
  }

  .detail-grid >>> .ag-cell-label-container {
    height: 42px;
  }

  .detail-grid >>> .ag-cell {
    height: 100%;
    width: 100%;
    font-size: 12px !important;
    border: none !important;
    display: flex;
    align-items: center;

    &.cell-span {
      border-bottom: 1px solid #DBDBDB !important;
    }
  }

  .detail-grid >>> .ag-cell-value {
    max-height: 100%;
    overflow: auto;
    color: #333;
  }
}
</style>
