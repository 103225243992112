<script setup>
import { useHarbourStore } from '@/stores/harbour-store';
import Vue from 'vue';
const harbourStore = useHarbourStore();
</script>

<template>
    <div :title="params.data.title" class="hrbr-sidebar-pane-template-item">
        <div  class="hrbr-sidebar-pane-template-item__icon">
            <i class="fa-light fa-file"></i>
        </div>
        <div class="hrbr-sidebar-pane-template-item__name-actions" >
            <div class="hrbr-sidebar-pane-template-item__title-rename">
                <div class="hrbr-sidebar-pane-template-item__title">
                    {{ params.data.title }}
                </div>
            </div>

            <div class="hrbr-sidebar-pane-template-item__actions" data-template-actions v-if="!params.customRowClick">
                <b-dropdown
                        class="hrbr-sidebar-pane-template-item__dropdown"
                        :append-to-body="true"
                        position="is-bottom-left">
                    <template #trigger>
                        <i class="fa-regular fa-ellipsis-vertical"></i>
                    </template>
                    <template #default>
                        <b-dropdown-item key="rename-template-group-key"  @click="harbourStore.createLink(params.data)">
                            <b-icon pack="far" icon="link"></b-icon>
                            <span>Start</span>
                        </b-dropdown-item>
                    </template>
                </b-dropdown>
            </div>
        </div>
    </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-pane-template-item {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    &__title{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__title-rename{
        max-width: 85%;
    }

    &__name-actions{
        justify-content: space-between;
        font-size: 15px;
        color: #666f75;
        line-height: 1.2;
        display: flex;
        align-items: center;
        column-gap: 10px;
        width: 100%;
        height: 36px;
        min-width: 0;
        padding: 3px 10px;
        position: relative;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
        overflow: hidden;
        transition: background 0.15s, color 0.15s;
    }

    &__actions {
        display: flex;
        align-items: center;
        gap: 5px;
        position: relative;
        z-index: 1;
        line-height: normal;
        visibility: hidden;
    }

    &:hover {
        .hrbr-sidebar-pane-template-item__actions {
            visibility: visible;
        }
    }

    &__icon {
        display: flex;
        flex-shrink: 0;
    }

    &__name {
        flex: 1;
        overflow: hidden;
    }

    &__name-text {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__name-input {
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        display: block;
        width: 100%;
        height: 100%;
        min-height: 24px;
        background: transparent;
        padding: 0;
        margin: 0;
        border: 0;
        outline: 0;
        box-shadow: none;
        appearance: none;
    }

    &__actions {
        display: flex;
        align-items: center;
        gap: 5px;
        flex-shrink: 0;
        position: relative;
        z-index: 1;
        line-height: normal;
    }

    &__add-template {
        font-size: 13px;
        cursor: pointer;
        transition: color 0.15s;

        &:hover {
            color: var(--hrbr-primary-color-active);
        }
    }

    &__dropdown {
        :deep(.dropdown-trigger) {
            font-size: 13px;
            cursor: pointer;
            padding: 0 4px;
            transition: color 0.15s;

            &:hover {
                color: var(--hrbr-primary-color-active);
            }
        }

        :deep(.background) {
            display: none !important;
        }

        &.is-active :deep(.dropdown-trigger) {
            visibility: visible !important;
        }
    }
}
</style>

<style lang="postcss">
.hrbr-sidebar-pane-template-item {
    &__dropdown {
        .dropdown-item {
            font-size: 14px;
            display: flex;
            align-items: center;
            gap: 6px;

            i {
                display: inline-flex;
                justify-content: center;
                width: 20px;
            }
        }
    }
}
</style>