<script setup>
import { storeToRefs } from 'pinia';
import { ref, computed, watch, onBeforeUnmount } from 'vue';
import { useMagicAnnotationsStore } from '@/stores/magic-annotations-store.js';
import { delay } from '@/utils/helpers/functions.js';

const magicAnnotationsStore = useMagicAnnotationsStore();
const {
  suggestionsList,
  firstAnnotationRef,
  scrolledToFirstRef,
  documentRef,
  termsAgreed,
  isFinishConsentModal,
  consentModalOpened,
  focusedSuggestionIndex,
  firstFieldEdited
} = storeToRefs(magicAnnotationsStore);

const btnOffset = 20;
const buttonText = ref('Start here');
const btnPosition = ref('');
const scrolledToNext= ref(false);
const listenerAttached = ref(false);
const isLast = computed(() => focusedSuggestionIndex.value === suggestionsList.value.length);

watch(scrolledToFirstRef, (value) => {
  if (value) {
    const annotationRect = firstAnnotationRef.value.getBoundingClientRect();
    btnPosition.value = `${annotationRect.bottom - annotationRect.height - btnOffset}px`;
  }
});

watch(firstFieldEdited, (value) => {
  if (value) {
    buttonText.value = 'Next';
  }
});

watch(focusedSuggestionIndex, async() => {
  if (!scrolledToNext.value) {
    const itemId = suggestionsList.value[focusedSuggestionIndex.value - 1]?.unique_box_id;
    if (!itemId) return;
    // wait for page to scroll
    await delay(200);
    const el = document.querySelector(`[data-id=${itemId}]`);
    const annotationRect = el.getBoundingClientRect();
    btnPosition.value = `${annotationRect.bottom - annotationRect.height - btnOffset}px`;
  }
});

onBeforeUnmount(() => {
  buttonText.value = 'Start here';
  btnPosition.value = '';
  focusedSuggestionIndex.value = 0;
});

const onScrollEnd = () => {
  if (scrolledToNext.value) {
    const el = document.querySelector(`[data-id=${suggestionsList.value[focusedSuggestionIndex.value - 1].unique_box_id}]`);
    const annotationRect = el.getBoundingClientRect();
    btnPosition.value = `${annotationRect.bottom - annotationRect.height - btnOffset}px`;
    scrolledToNext.value = false;
  }
};

const goToNextAnnotation = async () => {
  if (isLast.value) finish();

  if (!listenerAttached.value) {
    documentRef.value.addEventListener('scrollend', onScrollEnd);
    listenerAttached.value = true;
  }

  if (suggestionsList.value[focusedSuggestionIndex.value]) {
    const el = document.querySelector(`[data-id=${suggestionsList.value[focusedSuggestionIndex.value].unique_box_id}]`);
    const checkboxInput = document.querySelector(`#${suggestionsList.value[focusedSuggestionIndex.value].unique_box_id}`);

    const elRect = el.getBoundingClientRect();
    const scrollPosition = documentRef.value.scrollTop;
    const topOffset = 200;

    documentRef.value.scroll({
      behavior: 'smooth',
      top: scrollPosition + elRect.bottom - elRect.height - topOffset
    });
    scrolledToNext.value = true;

    // Focus next annotation
    suggestionsList.value[focusedSuggestionIndex.value].active = true;
    if (el) el.focus();
    if (checkboxInput) checkboxInput.focus();
    buttonText.value = 'Next';
    focusedSuggestionIndex.value = focusedSuggestionIndex.value + 1;
  }
};

const finish = () => {
  if (termsAgreed.value) {
    magicAnnotationsStore.saveDocument();
  } else {
    consentModalOpened.value = true;
    isFinishConsentModal.value = true;
  }
};
</script>

<template>
  <div
    :class="[
      'floating-next-button',
      isLast ? 'last': ''
    ]"
    :style="{top: btnPosition}"
    @click="goToNextAnnotation"
  >
    <span class="text">
      {{ isLast ? 'Click to finish' : buttonText }}
    </span>
  </div>
</template>

<style lang="postcss" scoped>
.floating-next-button {
  position: fixed;
  left: 0;
  top: 50%;
  padding: 12px 16px;
  border-radius: 0 40px 40px 0;
  box-shadow: 0 4px 12px 0 #00000026;
  background: linear-gradient(90deg, #7715B3 0%, #B015B3 100%);
  cursor: pointer;
  transition: .3s ease-in;
  &:hover {
    background: linear-gradient(90deg, #B015B3 0%, #7715B3 100%);
  }
  .text {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }
  &.last {
    background: #fff;
    .text {
      position: relative;
      background: linear-gradient(90deg, #7715B3 0%, #B015B3 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      &:after {
        content: '';
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background: linear-gradient(90deg, #7715B3 0%, #B015B3 100%);
      }
    }
    &:hover {
      .text {
        background: linear-gradient(90deg, #B015B3 0%, #7715B3 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &:after {
          background: linear-gradient(90deg, #B015B3 0%, #7715B3 100%);
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 4px 10px;
    .text {
      font-size: 12px;
    }
  }
}
</style>
