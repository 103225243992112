<script>
import { storeToRefs } from 'pinia';
import HrbrAdminGroupSettingsVue from '@/components/Modals/HrbrAdminGroupSettings.vue';

import linksApiService from '@/services/links-api-service';

import { useSettingsPageStore } from '../../../Settings/stores/settings-store';
import { useDashboardStore } from '@/stores/dashboard-store.js';

export default {
  components: {
  },
  data() {
    return {
      updateInProgress: false,
      row: null,
      activeGroups: [], // currently selected groups
      displayLimit: 2, // number of groups to display
    };
  },
  setup() {
    const dashboardStore = useDashboardStore();
    const { linksSelectedGroups } = storeToRefs(dashboardStore);
    return {
      settingsStore: useSettingsPageStore(),
      linksSelectedGroups
    };
  },
  watch: {
    linksSelectedGroups: {
      handler(val) {
        const groupsData = val[this.row.id];
        if (groupsData || groupsData.length === 0) this.activeGroups = groupsData;
      },
      deep: true
    }
  },
  computed: {
    displayedUserGroups() {
      return this.activeGroups.slice(0, this.displayLimit);
    },

    lastDisplayedGroup() {
      return this.displayedUserGroups[this.displayLimit-1] ||
      this.displayedUserGroups[this.displayedUserGroups.length - 1];
    },

    availableGroupsLoaded() {
      return this.settingsStore.userGroupsLoaded === true;
    },

    isDisabled() {
      return this.updateInProgress || !this.availableGroupsLoaded;
    }
  },
  created() {
    this.row = this.params.data;
    this.api = this.params.api;
    this.currentlyAddedIds = this.row.group_ids;
    this.activeGroups = this.settingsStore.listUserGroups
      .filter((group) => this.row.group_ids?.includes(group.id))
      .map((group) => {
        return {
          id: group.id,
          name: group.name,
          is_active: true,
          icon: group.icon,
        }
      });
  },
  methods: {
    async groupSaveHandler(selectedGroups) {
      this.updateInProgress = true;

      // instant update view
      this.activeGroups = selectedGroups.filter(group => group.is_active);

      // save selected groups
      const selectedGroupNames = selectedGroups.map((group) => group.name);

      const requestPromises = [];
      // separate requests for insert and delete
      selectedGroups.forEach((group) => {
        let requestPromise = null;
        if (group.is_active && !this.currentlyAddedIds.includes(group.id)) {
          console.log(`Groups Renderer - Adding "${group.name}"...`);
          requestPromise = linksApiService.addGroupToLink(this.row.id, group.id);
        } else if (!group.is_active) {
          console.log(`Groups Renderer - Removing "${group.name}"...`);
          requestPromise = linksApiService.removeGroupFromLink(this.row.id, group.id);
        } else {
          return;
        }

        requestPromises.push(requestPromise);
      });

      // no updates, return
      if (!requestPromises.length) {
        console.log('Groups Renderer - No updates to save');
        this.updateInProgress = false;
        return;
      }

      // concurrent requests
      const responses = await Promise.allSettled(requestPromises);

      // log failures
      const rejectedGroupIds = [];
      responses.forEach((response, index) => {
        if (response.status !== 'rejected') return;

        // save rejecrted group id for later
        rejectedGroupIds.push(selectedGroups[index].id);

        // display rejected group alert back to back
        setTimeout(() => {
          this.$buefy.toast.open({
            message: `Failed to save group settings for ${selectedGroupNames[index]}`,
            type: 'is-danger',
            duration: 1000,
          });
        }, 1000 * index);
      });

      this.currentlyAddedIds = selectedGroups
        .filter((group) => group.is_active)
        .map((group) => group.id);

      this.updateInProgress = false;
    },

    clickHandler() {
      const config = {
        component: HrbrAdminGroupSettingsVue,
        hasModalCard: false,
        trapFocus: true,
        canCancel: true,
        props: {
          activeGroups: this.activeGroups,
          linkCreator: this.row.creatorEmail || this.row.creator_email,
        },
        events: {
          save: async (selectedGroups) => await this.groupSaveHandler(selectedGroups)
        }
      }
      // open group sharing modal
      this.$buefy.modal.open(config);
    }
  }
};
</script>

<template>
  <div @click.stop="clickHandler" :class="{disabled: isDisabled}"> <!-- outer div -->
    <!-- no groups, display add group cta -->
    <div class="add-group-ctn" v-if="!activeGroups.length">
      <div class="btn add">
        <span>+</span>
      </div>
    </div>
    <!-- groups present, display groups -->
    <div v-else>
      <div class="group-label-ctn">

        <!-- first group present -->
        <div v-if="displayedUserGroups.length > 1">
          <div class="group-label"
          v-for="group in displayedUserGroups.slice(0, displayedUserGroups.length - 1)"
          :key="group.id"
          :title="group.name">
            <b-icon pack="far" :icon="group.icon || 'users'"></b-icon>
            <span class="text">{{ group.name }}</span>
          </div>
        </div>

        <!-- second group, if present -->
        <div class="group-label-plus-ctn">
          <div class="group-label last-group" :title="lastDisplayedGroup.name">
            <b-icon pack="far" :icon="lastDisplayedGroup.icon || 'users'"></b-icon>
            <span class="text">{{ lastDisplayedGroup.name }}</span>
          </div>
          <div class="group-label group-label-plus">
            <span class="text">+</span>
          </div>
        </div>

        <span class="remaining-count" v-if="activeGroups.length > displayLimit">
          {{ activeGroups.length - displayLimit }} more
        </span>

      </div>
    </div>
  </div> <!-- end outer div -->
</template>

<style scoped>
.add-group-ctn {
  cursor: pointer;
  user-select: none;
}
.add-group-ctn span.text {
  text-decoration: underline;
}
.group-label-ctn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  cursor: pointer;
}
.group-label-ctn .remaining-count {
  font-size: 11px;
  color: #6e6e6f;
}

.group-label-ctn .remaining-count:hover {
  text-decoration: underline;
}
.group-label {
  display: inline-block;
  max-width: 150px;
  border-radius: 20px;
  background-color: #f7f7f7;
  color: #6e6e6f;
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 2px auto;
  padding-right: 9px;
}

.group-label span.text {
  /* text-decoration: underline; */
}

.group-label-plus-ctn {
  display: flex;
}

.group-label-plus-ctn .group-label-plus,
.group-label-plus-ctn .last-group {
  display: inline-block;
}

.group-label-plus-ctn .group-label-plus {
  text-overflow: initial;
  margin-left: 3px;
  font-family: 'Font Awesome 5 Pro';
  text-align: center;
  padding: 3px 4px;
  font-size: 13px;
  position: relative;
  top: 1px;
  width: 26px;
}

.group-label-plus-ctn .last-group {
  width: 80%;
}

.btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  padding: 4px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background-color: white;
}
.btn:hover {
  border: 1px solid #b5b5b5;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

</style>
