<script>
import HrbrEmailSendModal from '@/components/Modals/HrbrEmailSend.vue';
import HrbrModalCopyTemplate from '@/components/Modals/HrbrModalCopyTemplate.vue';
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject';
import { useDashboardStore } from '@/stores/dashboard-store';
import { useHarbourStore } from '@/stores/harbour-store';
import { useWorkflowsStore } from '@/stores/workflows-store';
import { makeEmailInputDataStore } from '@/utils/helpers/email-input-helpers';
import { ToastProgrammatic as Toast } from 'buefy';
import { mapState } from 'pinia';

export default {
  name: 'ActionsRenderer',
  setup() {
    return {
      dashboardStore: useDashboardStore(),
      harbourStore: useHarbourStore(),
      workflowsStore: useWorkflowsStore(),
    };
  },
  data() {
    return {
      field: null,
      linkObject: null,
      isActive: false,
      isDuplicating: false,
      toastDuration: 3000,

      // Cloning
      copiedTemplateId: null,
      templateGroupId: null,
      ckeditorFileId: null,
    };
  },
  created() {
    this.field = this.params.colDef.field;
    this.linkObject = useLinkObject(this.params.data);
  },
  beforeUnmount() {
    this.linkObject = null;
  },
  methods: {
    copyAgreement() {
      this.isDuplicating = true;
      this.showToast('Copying link...');
      this.copyRequest();
    },

    async copyRequest(asTemplate = false) {
      const linkItem = {};
      linkItem.ckeditorFileId = this.linkObject.ckeditorAgreementId;
      linkItem.agreementId = this.linkObject.agreementId;
      linkItem.client_importbyurl_agreementid = this.linkObject.agreementId;
      linkItem.client_importbyurl_linkrecordtitle = this.linkObject.title;
      linkItem.client_importbyurl_custominputvaluesjsonstr = {
        ckeditorFileId: this.linkObject.ckeditorAgreementId,
      };
      linkItem.parent = this.linkObject.folder;
      const response = await this.harbourStore.cloneLink(linkItem, asTemplate);

      if (asTemplate) {
        this.openPublishSettingsModal(true, response.updatedAgreementId, response.updatedCkId)
      } else {
        this.openCopyInAgreementEditor(response.updatedAgreementId, asTemplate, response.updatedCkId)
      }
      this.isDuplicating = false;
    },

    async openPublishSettingsModal(isAgreementToTemplate, copiedTemplateId, ckeditorFileId = null) {
      this.copiedTemplateId = copiedTemplateId;
      this.ckeditorFileId = ckeditorFileId;

      const self = this;
      this.$buefy.modal.open({
        parent: this,
        component: HrbrModalCopyTemplate,
        hasModalCard: true,
        props:{
          "isagreementtotemplate": isAgreementToTemplate,
          "parentUpdateAction": self.changeTemplatePublishSettings
        }
      });
    },

    async changeTemplatePublishSettings(templateGroupId) {
      const result = await this.harbourStore.changeAgreementPublishSettings(
        this.copiedTemplateId, templateGroupId, this.ckeditorFileId
      );

      if (!result) return;
      this.openCopyInAgreementEditor(this.copiedTemplateId, true, this.ckeditorFileId)
    },

    openCopyInAgreementEditor(copiedTemplateId, isTemplate, ckeditorFileId = null) {
      // Open cloned link in AE
      const props = {};
      if (ckeditorFileId) props.ckeditorFileId = ckeditorFileId;
      if (isTemplate) props.creationMode = 'template';
      else props.creationMode = 'agreement';

      if (copiedTemplateId) {
        props.agreementId = copiedTemplateId;
        props.isCopy = true;
      } else{
        alert("Not able to copy as agreement/template at this time. Try again in a bit or contact suppport@harbourshare.com");
        return;
      }
      this.$openAgreementEditorModal({ props });
      return;
    },

    copyAsTemplate() {
      this.showToast('Copying link and starting new template...');
      this.copyRequest(true);
    },

    showToast(message) {
      Toast.open({
        duration: this.toastDuration,
        message: message,
        position: 'is-bottom',
        type: 'is-black',
      });
    },

    handleNudgeClick() {
      const emailsToSign = this.linkObject.emailsToSign();

      let agreementTitle = this.linkObject.title;
      if (!agreementTitle || agreementTitle == "-") {
        agreementTitle = agreementTitle || '';
      }
      const emailInputData = makeEmailInputDataStore();
        //Open view sent messages
        this.$buefy.modal.open({
          parent: this,
          component: HrbrEmailSendModal,
          hasModalCard: true,
          props: {
            requestemailtype : 'NUDGEEMAIL',
            requesttitle: '',
            requestmessage: '',
            toemailsarray: emailsToSign,
            ccemailsarray:[],
            linkpasscode: '',
            agreementtitle: agreementTitle,
            agreelinkdisplayid: this.linkObject.id,
            emailInputData: emailInputData,
            autoClose: true
          }
        });
    },

    editLinkHandler() {
      const props = {
        linkDisplayIdToEdit: this.linkObject.id,
        linkDisplayId: this.linkObject.id,
        agreementId: this.linkObject.agreementId,
        templateId: this.linkObject.templateId,
        folderId: this.harbourStore.currentFolderId,
        superdocId: this.linkObject.superdocId,
      };

      // Check if this is a CK link
      const ckeditorFileId = this.linkObject.ckeditorAgreementId;
      if (ckeditorFileId) {
        props.ckeditorFileId = ckeditorFileId;
      }

      props.isCompletedApproval = this.completedApproval;
      this.$openAgreementLinkModal({ props });
    },

    handleViewFolder() {
      const query = { folderid: this.linkObject.folder };
      this.$router.push({ name: 'folders', query });
    },

    handleViewSignerLink() {
      window.open(this.linkObject.url);
    },

  },
  computed: {
    isSuperdoc() {
      return !!this.params.data?.superdocId;
    },
    completedApproval() {
      const workflows = this.linkObject?.workflowsStatus;
      for (let wf of workflows) {
        if (!wf.snapshot) continue;
        if (this.isWorkflowApproved(wf.snapshot)) return true;
      }
      return false;
    },
    getMode() {
      return this.linkObject.authMethod?.authmode;
    },
    disableEditing() {
      const mode = this.getMode === 'EMAILS';
      const completed = this.linkObject.getCompletionState === 'COMPLETED';
      return this.dashboardStore.linkHasPendingApproval(this.params) || (mode && completed);
    },
    disableDuplicate() {
      return this.dashboardStore.linkHasPendingApproval(this.params) || this.isDuplicating;
    },
    disableNudge() {
      return this.dashboardStore.linkHasPendingApproval(this.params);
    },
    disableViewSignerLink() {
      return this.dashboardStore.linkHasPendingApproval(this.params);
    },
    disableViewFolder() {
      return this.dashboardStore.linkHasPendingApproval(this.params);
    },
    disableCopyAsTemplate() {
      return this.dashboardStore.linkHasPendingApproval(this.params);
    },
    disableTransferLinkOwnership() {
      return this.dashboardStore.linkHasPendingApproval(this.params);
    },
    ...mapState(useWorkflowsStore, ['isWorkflowApproved']),
  },
};
</script>

<template>
  <div class="actions-container">
    <div class="actions-buttons">
      <button
        title="Open agreement link editor"
        class="hrbr-grid-button first-button"
        :disabled="disableEditing"
        @click.stop="editLinkHandler">
        <i class="fal fa-pencil"></i>
        <span>Edit</span>
      </button>
      <button
        title="Duplicate agreement and start new link"
        class="hrbr-grid-button"
        style="min-width: 70px"
        :disabled="disableDuplicate"
        @click.stop="copyAgreement">
        <div v-if="!isDuplicating">
          <i class="fal fa-copy"></i>
          <span>Clone</span>
        </div>
        <div v-else style="width: 100%">
          <i class="fal fa-spinner-third fa-spin"></i>
        </div>
      </button>
      <button
        title="Send reminder emails"
        class="hrbr-grid-button"
        :disabled="disableNudge"
        @click.stop="handleNudgeClick">
        <i class="fal fa-hand-point-right"></i>
        <span>Nudge</span>
      </button>

      <b-dropdown
        position="is-bottom-left"
        animation=""
        append-to-body
        :triggers="['click']"
        aria-role="list"
        style="margin-left: 0px"
        class="dropdown"
        @click.native.stop
      >
        <button class="hrbr-grid-button hrbr-grid-overflow-button" slot="trigger" icon-pack="fas" icon-right="caret-down">
          <b-icon pack="fas" icon="ellipsis-vertical"></b-icon>
        </button>
        <b-dropdown-item @click.native.stop="handleViewSignerLink" :disabled="this.disableViewSignerLink">
          <b-icon
            class="myLinksIcon"
            pack="fal"
            icon="external-link-alt"
            size="is-small"
            title="View agreement workflow"></b-icon>
          <span>View signer link in new tab</span>
        </b-dropdown-item>
        <b-dropdown-item @click.native.stop="handleViewFolder" :disabled="this.disableViewFolder">
          <b-icon
            class="myLinksIcon"
            pack="fal"
            icon="folder-open"
            size="is-small"
            title="Open agreement workflow destinaton folder"></b-icon>
          <span>View destination folder</span>
        </b-dropdown-item>
        <b-dropdown-item @click.native.stop="copyAsTemplate" :disabled="this.disableCopyAsTemplate">
          <b-icon
            class="myLinksIcon"
            pack="fal"
            icon="copy"
            size="is-small"
            title="Copy and start as new template"></b-icon>
          <span>Copy and start new template</span>
        </b-dropdown-item>
        <b-dropdown-item @click.native.stop="dashboardStore.transferLinkOwnership(linkObject)" :disabled="this.disableTransferLinkOwnership">
          <b-icon
            class="myLinksIcon"
            pack="fal"
            icon="arrow-right-arrow-left"
            size="is-small"
            title="Transfer ownership"
            alt="Transfer ownership"></b-icon>
          <span>Transfer ownership</span>
        </b-dropdown-item>
        <b-dropdown-item @click.native.stop="dashboardStore.deleteLink(linkObject)">
          <b-icon
            class="myLinksIcon"
            pack="fal"
            icon="trash"
            size="is-small"
            title="Delete link"
            alt="Delete link"></b-icon>
          <span>Delete link</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<style scoped>
.actions-container {
  display: flex;
  justify-content: center;
  white-space: nowrap !important;
}
.actions-buttons {
  display: flex;
  white-space: nowrap !important;
}
button i {
  margin-right: 5px;
  padding: 0;
}

.dropdown-menu {
  z-index: 10 !important;
}
.template-copy.fa-stack.fa-2x {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
  /* font-size: 7px; */
  color: #b7b7b7;
  margin: 3px 0.4rem 1px;
}
.actions-buttons button:not(:first-child):not(:last-child) {
  border-radius: 0;
  border-right: none;
}

.first-button {
  border-radius: 4px 0 0 4px;
  border-right: 0px;
}
.last-button {
  border-radius: 0 4px 4px 0;
}
.template-copy.fa-file.fa-stack-2x {
  /* font-size: 0.875rem !important; */
  top: -2px;
}
.template-copy.fa-file-contract.fa-stack-2x {
  /* font-size: 0.875rem !important; */
  top: 0px;
  padding-right: 4px;
  background: white;
  width: 7px;
  color: #686969;
}
.myLinksIcon {
  margin-top: 1px;
  cursor: pointer;
  margin-right: 6px;
  margin-left: 0px;
  color: #acacac;
}
.myLinksIcon:hover {
  color: black;
}
.fa-spin {
  animation-duration: 0.5s;
}
</style>
