<script setup>
import { useSearchStore } from '@/components/Search/stores/search-store';
import { useSidebarStore } from '@/components/Sidebar/stores/sidebar-store';
import { useBillingStore } from '@/pages/Settings/stores/billing-store.js';
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store.js';
import { useHarbourStore } from '@/stores/harbour-store';
import Vue, { computed, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router/composables';
import HrbrAppHeaderMenu from './HrbrAppHeaderMenu.vue';

const harbourStore = useHarbourStore();
const sidebarStore = useSidebarStore();
const searchStore = useSearchStore();
const billingStore = useBillingStore();
const templatesStore = useTemplatesStore();

// Usage of getCurrentInstance is discouraged in app code.
// But it's necessary to support components that use buefy.
const instance = getCurrentInstance();

const route = useRoute();
const currentRouteName = computed(() => route.name);

const viewNameMap = new Map([
  ['dashboard', 'Dashboard'],
  ['signed-by-me', 'Dashboard'],
  ['awaiting-my-review', 'Dashboard'],
  ['drafts', 'Drafts'],
  ['templates', 'Templates'],
  ['folders', 'Folders'],
  ['settings', 'Settings'],
  ['automations', 'Automations'],
  ['advancedsearch', 'Search results'],
  ['content', 'Content'],
  ['workflows', 'Workflows']
]);

const searchPermissions = ['permissions.globalsearch.view'];
const newAgreementPermissions = ['permissions.agreebuilder.view'];

const isDisabledSearch = computed(() => {
  return !harbourStore.checkUserAuthPermissions(searchPermissions);
});

const isDisabledNewAgreement = computed(() => {
  return !harbourStore.checkUserAuthPermissions(newAgreementPermissions);
});

const currentViewName = computed(() => {
  const viewName = viewNameMap.get(currentRouteName.value);
  return viewName || '';
});

const openAgreementEditor = () => {
  const props = {
    initial_workspaceCustomProperties: harbourStore.workspaceCustomProperties,
    system_email: harbourStore.contextDict.systememail,
    gae_service: harbourStore.contextDict.gae_service,
    creationMode: 'agreement',
    lastbrand: true,
    parent: instance.proxy,
    templateGroupId: templatesStore.lastUsedTemplateGroup?.groupId,
  };
  Vue.prototype.$openAgreementEditorModal({ props });
};

const openPricingTableModal = () => {
  billingStore.openPricingTableModal();
};

const openFinishUpgradingPage = () => {
  const checkoutUrl = billingStore.getActiveSubscription.checkout_session_url;
  if (checkoutUrl) {
    window.location.href = checkoutUrl;
  }
};

const handleUpgradeClick = () => {
  if (billingStore.isPaymentPending) {
    openFinishUpgradingPage();
  } else {
    openPricingTableModal();
  }
};

const openSearch = () => {
  searchStore.openSearch();
};

const toggleSidebar = () => {
  sidebarStore.setSidebarOpen(!sidebarStore.isSidebarOpen);
};
</script>

<template>
  <header class="hrbr-app-header">
    <div class="hrbr-app-header__container">
      <div class="hrbr-app-header__part hrbr-app-header__part--left">
        <div class="hrbr-app-header__toggle" @click="toggleSidebar">
          <div class="hrbr-app-header__toggle-wrap">
            <span class="hrbr-app-header__toggle-line"></span>
          </div>
        </div>
        <div :style="{ color: harbourStore.titleColor }" class="hrbr-app-header__view-name">
          {{ currentViewName }}
        </div>
      </div>

      <div class="hrbr-app-header__part hrbr-app-header__part--right">
        <button class="hrbr-app-header__search" :class="{
          'hrbr-app-header__search': true,
          'hrbr-app-header__search--disabled': isDisabledNewAgreement,
        }" @click="openSearch" :disabled="isDisabledSearch">
          <i class="fa-regular fa-magnifying-glass"></i>
          <span>Search...</span>
          <kbd>⌘K</kbd>
        </button>

        <button class="hrbr-app-header__upgrade-today hrbr-app-header__action_button" @click="handleUpgradeClick"
          v-if="billingStore.showFreemiumButton && billingStore.subscriptionPlanLoaded">
          <i class="fa-solid fa-star"></i>
          <i class="fa-light fa-star mobile"></i>
          <span v-if="!billingStore.isPaymentPending">Upgrade today</span>
          <span v-else>Finish upgrading</span>
        </button>

        <button id="new-agreement" class="hrbr-app-header__new-agreement hrbr-app-header__action_button" :class="{
          'hrbr-app-header__new-agreement': true,
          'hrbr-app-header__action_button': true,
          'hrbr-app-header__action_button--disabled': isDisabledNewAgreement,
        }" :disabled="isDisabledNewAgreement" @click="openAgreementEditor">
          <i class="fa-solid fa-plus"></i>
          <i class="fa-light fa-file-signature mobile"></i>
          <span>New agreement</span>
        </button>

        <HrbrAppHeaderMenu />
      </div>
    </div>
  </header>
</template>

<style lang="postcss" scoped>
.hrbr-app-header {
  --hrbr-app-header-height: 64px;

  display: flex;
  flex-direction: column;
  height: var(--hrbr-app-header-height);
  padding: 0 20px;

  &__container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 10px 0;
  }

  &__part {
    display: flex;
    align-items: center;
    gap: 10px;

    &--left {
      justify-content: flex-start;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  &__view-name {
    font-size: 19px;
    font-weight: 500;
    color: #fff;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    padding: 1px 18px;
    height: 40px;
    text-shadow: 0px 1px 1.2px rgb(0 0 0 / 23%);
    background-color: #00000008;
    backdrop-filter: blur(2px);
    letter-spacing: 0.6px;
    border-radius: 4px;
    user-select: none;
  }

  &__search {
    font-family: inherit;
    font-size: 14px;
    color: var(--hrbr-black-color);
    letter-spacing: 0.2px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 6px;
    width: 146px;
    height: 40px;
    padding: 0 6px 0 8px;
    background: #fff;
    border-radius: 8px;
    border: 1.5px solid white;
    cursor: text;
    outline: none;
    appearance: none;

    &--disabled {
      cursor: not-allowed;
      color: #777;
    }

    &:hover {
      span {
        opacity: 1;
      }
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.75;
      transition: opacity 0.15s;
    }

    kbd {
      font-family: inherit;
      font-size: 12px;
      font-weight: 500;
      color: #00000091;
      padding: 0 6px;
      height: 22px;
      line-height: 22px;
      background: #e4e9ec4f;
      border-radius: 4px;
      margin-left: auto;
    }
  }

  &__new-agreement {
    background: linear-gradient(90deg, #0133b7e0 30%, #0e45d3c7);
    color: #ffffffeb !important;
  }

  &__upgrade-today {
    background: linear-gradient(108.14deg, #ffc300 34.57%, #ffbc00 64.02%, #ffb200 82.03%);
  }

  &__action_button {
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: #fff;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 6px;
    height: 40px;
    padding: 5px 26px;
    border: none;
    border-radius: 120px;
    position: relative;
    cursor: pointer;
    outline: none;
    appearance: none;
    overflow: hidden;
    min-width: 192px;

    &::before {
      content: '';
      display: block;
      background: rgb(47 90 137 / 30%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transition: visibility 0.15s, opacity 0.15s;
      z-index: 0;
    }

    &--disabled {
      cursor: not-allowed;

      &::before {
        background: rgb(47 90 137 / 30%);
        visibility: visible;
        opacity: 1;
      }
    }

    &:hover {
      &::before {
        visibility: visible;
        opacity: 1;
      }
    }

    span,
    i {
      position: relative;
    }

    i {
      font-size: 13px;

      &.mobile {
        display: none;
      }
    }
  }

  &__toggle {
    display: none;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    pointer-events: all;
    user-select: none;
    background-color: #00000008;
    backdrop-filter: blur(2px);
    border-radius: 4px;
    -webkit-tap-highlight-color: transparent;
  }

  &__toggle-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 22px;
      background: #fff;
    }

    &::before {
      transform: translateY(-6px);
    }

    &::after {
      transform: translateY(6px);
    }
  }

  &__toggle-line {
    display: block;
    height: 2px;
    width: 22px;
    background: #fff;
  }
}

@media (max-width: 992px) {
  .hrbr-app-header {
    &__toggle {
      display: flex;
    }
  }
}

@media (max-width: 768px) {
  .hrbr-app-header {
    &__part {
      gap: 6.5px;
    }

    &__view-name {
      font-size: 12px;
      height: 36px;
      padding: 1px 5px;
    }

    &__search {
      justify-content: center;
      width: 46px;
      height: 36px;
      padding: 5px;

      span,
      kbd {
        display: none;
      }
    }

    &__action_button {
      justify-content: center;
      width: 46px;
      height: 36px;
      padding: 5px;
      min-width: auto;

      i {
        display: none;
      }

      i.mobile {
        font-size: 15px;
        display: block;
      }

      span {
        display: none;
      }
    }

    &__toggle {
      width: 34px;
      height: 36px;
    }

    &__toggle-wrap {

      &::before,
      &::after {
        width: 20px;
      }
    }

    &__toggle-line {
      width: 20px;
    }
  }
}
</style>